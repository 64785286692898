import React from 'react'
import './About.css'

const About = () => {
  return (
    <div className='about-container'>
     <center> <h1>Still Under <br /> Work</h1> </center>
    </div>
  )
}

export default About;